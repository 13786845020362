import React from "react";
import "./WorkOne.scss";
import Fancybox from "../../FancyApps";
import parnami1 from "../../assets/images/workFinal/parnami/parnami1.jpg";
import parnami2 from "../../assets/images/workFinal/parnami/parnami2.jpg";
import parnami3 from "../../assets/images/workFinal/parnami/parnami3.jpg";
import parnami4 from "../../assets/images/workFinal/parnami/parnami4.jpg";
import parnami5 from "../../assets/images/workFinal/parnami/parnami5.jpg";
import parnami6 from "../../assets/images/workFinal/parnami/parnami6.jpg";
import parnami7 from "../../assets/images/workFinal/parnami/parnami7.jpg";
import parnami8 from "../../assets/images/workFinal/parnami/parnami8.jpg";
import parnami9 from "../../assets/images/workFinal/parnami/parnami9.jpg";
import parnami10 from "../../assets/images/workFinal/parnami/parnami10.jpg";
import parnami11 from "../../assets/images/workFinal/parnami/parnami11.jpg";
import parnami12 from "../../assets/images/workFinal/parnami/parnami12.jpg";
import parnami13 from "../../assets/images/workFinal/parnami/parnami13.jpg";
import parnami14 from "../../assets/images/workFinal/parnami/parnami14.jpg";
import parnami15 from "../../assets/images/workFinal/parnami/parnami15.jpg";
import parnami16 from "../../assets/images/workFinal/parnami/parnami16.jpg";
import parnami17 from "../../assets/images/workFinal/parnami/parnami17.jpg";
import parnami18 from "../../assets/images/workFinal/parnami/parnami18main.jpg";
import parnami19 from "../../assets/images/workFinal/parnami/parnami19.jpg";
import parnami20 from "../../assets/images/workFinal/parnami/parnami20.jpg";
import parnami21 from "../../assets/images/workFinal/parnami/parnami21.jpg";
import { NavLink } from "react-router-dom";

const WorkOne = () => {
  return (
    <div className="container-fluid workDetail pb-5">
      <section className="row justify-content-center pt-5">
        <div className="col-12 col-md-10 text-center pt-5">
          <h2 className="text-center mb-5 mt-5 ">PARNAMI'S HOUSE</h2>
          {/* <h6 className="text-secondary pb-5">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores,
            quidem. Iusto, sunt ab! Vel, aperiam. Iusto recusandae assumenda
            magnam iste corporis optio cumque quod nobis vero labore, dolores
            quasi rerum! Lorem ipsum dolor sit amet consectetur, adipisicing
            elit. Maiores, quidem. Iusto, sunt ab! Vel, aperiam.
          </h6> */}
        </div>
        <div className="col-12 col-lg-10 px-3 px-md-5 px-lg-3">
          <Fancybox>
            <div className="row justify-content-between align-items-center">
              <a
                data-fancybox="gallery-work1"
                href={parnami1}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami1}
                  alt="Work 1"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami2}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami2}
                  alt="Work 2"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami3}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami3}
                  alt="Work 3"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami4}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami4}
                  alt="Work 4"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami5}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami5}
                  alt="Work 5"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami6}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami6}
                  alt="Work 6"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami7}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami7}
                  alt="Work 7"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami8}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami8}
                  alt="Work 8"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami9}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami9}
                  alt="Work 9"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami10}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami10}
                  alt="Work 10"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami11}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami11}
                  alt="Work 11"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami12}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami12}
                  alt="Work 12"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami13}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami13}
                  alt="Work 13"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami14}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami14}
                  alt="Work 14"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami15}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami15}
                  alt="Work 15"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami16}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami16}
                  alt="Work 16"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami17}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami17}
                  alt="Work 17"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami18}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami18}
                  alt="Work 18"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami19}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami19}
                  alt="Work 19"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami20}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami20}
                  alt="Work 20"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={parnami21}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={parnami21}
                  alt="Work 21"
                  className="workDetail-image w-100"
                />
              </a>
            </div>
          </Fancybox>
        </div>
        <div className="col-12 text-center mt-5 mb-4 my-md-5">
          <NavLink
            to="/work"
            className="btn-black w-auto rounded-pill py-3 px-5 shadow-lg"
          >
            View Other Projects
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default WorkOne;
