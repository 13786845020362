import React from "react";
// import Fancybox from "../../FancyApps";
import "./Home.scss";
import about from "../../assets/images/home/about.jpg";
import aboutMobile from "../../assets/images/home/about-mobile.jpg";
import dash4main from "../../assets/images/workFinal/dash/dash4main.jpg";
import munjal4main from "../../assets/images/workFinal/munjal/munjal4main.jpg";
import parnami18main from "../../assets/images/workFinal/parnami/parnami18main.jpg";
// import moodboard1 from "../../assets/images/home/moodboard1.jpg";
// import moodboard2 from "../../assets/images/home/moodboard2.jpg";
// import moodboard3 from "../../assets/images/home/moodboard3.jpg";
// import moodboard4 from "../../assets/images/home/moodboard4.jpg";
// import moodboard5 from "../../assets/images/home/moodboard5.jpg";
// import moodboard6 from "../../assets/images/home/moodboard6.jpg";
import cloud from "../../assets/icons/cloud.png";
import midCentury from "../../assets/videos/midCentury.mp4";
import AppContact from "../../components/AppContact/AppContact";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div className="container-fluid home">
      <section className="row home-banner">
        <video
          autoPlay
          muted
          loop
          src={midCentury}
          width={"100%"}
          className="d-sm-block d-none col-12 px-0"
        />
        <video
          autoPlay
          muted
          loop
          src={midCentury}
          height={"100%"}
          width={"auto"}
          className="d-block d-sm-none col-12 px-0"
        />
      </section>

      <section className="row home-about justify-content-center align-items-center flex-column">
        <div className="col-12">
          <h2 className="text-center">WHO WE ARE</h2>
        </div>
        <div className="col-12 mt-5">
          <div className="row flex-md-row flex-column-reverse position-relative">
            <div className="col-md-6 mt-5 mt-md-4 d-flex align-items-center px-5 px-md-3  d-none d-md-block">
              <img src={about} alt="" className="w-100 d-none d-md-block" />
            </div>
            <img
              src={aboutMobile}
              alt=""
              className="position-absolute d-block d-md-none home-about-mobile"
            />
            <div className="col-md-6 px-3 mt-0 mt-md-4 px-lg-5">
              <h5>
                We create beautiful interiors that our clients can call home…
              </h5>
              <hr />
              <h6 className="mt-4 text-secondary">
                Sometimes when you walk in a room, there’s just something about
                it. You can’t quite put your finger on why, but instantly
                there’s a connection.
              </h6>
              <h6 className="mt-3 text-secondary">
                Chances are, that room was designed to make you feel precisely
                that way.
              </h6>
              <h6 className="mt-3 text-secondary">
                Proportion, light, flow, function, composition, balance, not to
                mention architectural finishes, building codes and
                requirements-these are all factors at play.
              </h6>
              <h6 className="mt-3 text-secondary">
                Interior Design is much more than cushion covers and wall
                colours (though we do that as well).
              </h6>
              <div className="text-center text-md-start mt-5">
                <NavLink
                  to="/about"
                  className="btn-black w-auto rounded-pill py-2 px-4 "
                >
                  Explore More
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="row home-projects justify-content-center align-items-center py-5">
        <h2 className="text-center mb-5 mt-md-5">OUR WORK</h2>
        <div className="col-12 col-md-10">
          <div className="row justify-content-evenly">
            <div className="col-lg-4 col-12 col-sm-5 home-projects__left">
              <NavLink
                to="/work-one"
                className="d-flex flex-column  home-projects__large-box mb-3 mb-sm-5 mt-5"
              >
                <img
                  src={parnami18main}
                  alt="Parnami's House"
                  className="w-100 home-projects__large-box-image"
                />
                <h6 className="home-projects__name text-uppercase mt-3 mb-2">
                  Parnami's House
                </h6>
              </NavLink>
            </div>
            <div className="col-lg-4 col-12 col-sm-5 home-projects__right">
              <NavLink
                to="/work-two"
                className="d-flex flex-column  home-projects__small-box mb-3 mb-sm-5"
              >
                <img
                  src={munjal4main}
                  alt="Munjal's Residence"
                  className="w-100 home-projects__small-box-image"
                />
                <h6 className="home-projects__name text-uppercase mt-3 mb-2">
                  Munjal's Residence
                </h6>
              </NavLink>
              <NavLink
                to="/work-three"
                className="d-flex flex-column  home-projects__small-box mb-3 mb-sm-5"
              >
                <img
                  src={dash4main}
                  alt="Dash Abode"
                  className="w-100 home-projects__small-box-image"
                />
                <h6 className="home-projects__name text-uppercase mt-3 mb-2">
                  Dash Abode
                </h6>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-12 text-center mt-5 mb-5">
          <NavLink
            to="/work"
            className="btn-white-outline w-auto rounded-pill py-3 px-5"
          >
            View all Projects
          </NavLink>
        </div>
      </section>

      <section className="row justify-content-center align-items-center">
        <div className="col-12">
          <div className="row home-process">
            <div className="col-12 position-relative home-moodboard__heading">
              <h2 className="text-center mb-5 pb-0 pb-md-5">OUR APPROACH</h2>
              <img
                src={cloud}
                alt=""
                className="home-moodboard__heading-image"
              />
            </div>
            {/* <h2 className="text-center mb-5 pb-0 pb-md-5">OUR APPROACH</h2> */}

            <div className="col-md-3 d-flex flex-column justify-content-center align-items-center position-relative">
              <div className="home-process__number">01</div>
              <div className="home-process__line"></div>
              <div className="home-process__content">
                <h6 className="home-process__content-heading text-center text-uppercase">
                  Meet & Agree
                </h6>
                <p className="home-process__content-subheading text-center mt-3">
                  Our kick-off meeting. Work through inspiration, design styles,
                  loose plans and ideas.
                </p>
              </div>
            </div>
            <div className="col-md-3 d-flex flex-column-reverse flex-md-column justify-content-center align-items-center position-relative home-process__reverse">
              <div className="home-process__content">
                <h6 className="home-process__content-heading text-center text-uppercase">
                  Concept Design
                </h6>
                <p className="home-process__content-subheading text-center mt-3">
                  We will present mood-boards, a loose concept supported by
                  floor plans and finish designing.
                </p>
              </div>
              <div className="home-process__line"></div>
              <div className="home-process__number">02</div>
            </div>
            <div className="col-md-3 d-flex flex-column justify-content-center align-items-center position-relative">
              <div className="home-process__number">03</div>
              <div className="home-process__line"></div>
              <div className="home-process__content">
                <h6 className="home-process__content-heading text-center text-uppercase">
                  Design Development & Documentation
                </h6>
                <p className="home-process__content-subheading text-center mt-3">
                  This includes completion of finishes schedules, floor plans,
                  elevations, and 3D renders.
                </p>
              </div>
            </div>
            <div className="col-md-3 d-flex flex-column-reverse flex-md-column justify-content-center align-items-center position-relative home-process__reverse">
              <div className="home-process__content">
                <h6 className="home-process__content-heading text-center text-uppercase">
                  Construction/Design Management
                </h6>
                <p className="home-process__content-subheading text-center mt-3">
                  We will ensure your design is realised at the end of the
                  project before final handover.
                </p>
              </div>
              <div className="home-process__line"></div>
              <div className="home-process__number">04</div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="row home-moodboard justify-content-center align-items-start pb-5">
        <div className="col-12 position-relative home-moodboard__heading">
          <h2 className="text-center">MOODBOARDS</h2>
          <img src={cloud} alt="" className="home-moodboard__heading-image" />
        </div>
        <div className="col-12 col-lg-10 mb-md-5 ">
          <Fancybox>
            <div className="row">
              <a
                data-fancybox="gallery"
                href={moodboard1}
                className="col-md-4 col-6 mb-3 mb-md-4"
              >
                <img
                  src={moodboard1}
                  alt="moodboard1"
                  className=" home-moodboard__image w-100"
                />
              </a>
              <a
                data-fancybox="gallery"
                href={moodboard2}
                className="col-md-4 col-6 mb-3 mb-md-4"
              >
                <img
                  src={moodboard2}
                  alt="moodboard2"
                  className=" home-moodboard__image w-100"
                />
              </a>
              <a
                data-fancybox="gallery"
                href={moodboard3}
                className="col-md-4 col-6 mb-3 mb-md-4"
              >
                <img
                  src={moodboard3}
                  alt="moodboard3"
                  className=" home-moodboard__image w-100"
                />
              </a>
              <a
                data-fancybox="gallery"
                href={moodboard4}
                className="col-md-4 col-6 mb-3 mb-md-4"
              >
                <img
                  src={moodboard4}
                  alt="moodboard4"
                  className=" home-moodboard__image w-100"
                />
              </a>
              <a
                data-fancybox="gallery"
                href={moodboard5}
                className="col-md-4 col-6 mb-3 mb-md-4"
              >
                <img
                  src={moodboard5}
                  alt="moodboard5"
                  className=" home-moodboard__image w-100"
                />
              </a>
              <a
                data-fancybox="gallery"
                href={moodboard6}
                className="col-md-4 col-6 mb-3 mb-md-4"
              >
                <img
                  src={moodboard6}
                  alt="moodboard6"
                  className=" home-moodboard__image w-100"
                />
              </a>
            </div>
          </Fancybox>
        </div>
      </section> */}

      <AppContact />
    </div>
  );
};

export default Home;
