import React from "react";
import "../Home/Home.scss";
import "./Work.scss";
import { NavLink } from "react-router-dom";
import workHero from "../../assets/images/workFinal/workHero.jpg";
import dash4main from "../../assets/images/workFinal/dash/dash4main.jpg";
import munjal4main from "../../assets/images/workFinal/munjal/munjal4main.jpg";
import parnami18main from "../../assets/images/workFinal/parnami/parnami18main.jpg";

const Work = () => {
  return (
    <div className="container-fluid work">
      <section className="row work-hero">
        <div className="col-12 px-0">
          <img src={workHero} alt="work-hero" className="w-100" />
        </div>
      </section>

      <section className="row home-projects justify-content-center align-items-center py-5">
        <h2 className="text-center mb-5 mt-md-5">OUR WORK</h2>
        <div className="col-12 col-md-10">
          <div className="row justify-content-evenly">
            <div className="col-lg-4 col-12 col-sm-5 home-projects__left">
              <NavLink
                to="/work-one"
                className="d-flex flex-column  home-projects__large-box mb-3 mb-sm-5 mt-5"
              >
                <img
                  src={parnami18main}
                  alt="Parnami's House"
                  className="w-100 home-projects__large-box-image"
                />
                <h6 className="home-projects__name text-uppercase mt-3 mb-2">
                  Parnami's House
                </h6>
              </NavLink>
            </div>
            <div className="col-lg-4 col-12 col-sm-5 home-projects__right">
              <NavLink
                to="/work-two"
                className="d-flex flex-column  home-projects__small-box mb-3 mb-sm-5"
              >
                <img
                  src={munjal4main}
                  alt="Munjal's Residence"
                  className="w-100 home-projects__small-box-image"
                />
                <h6 className="home-projects__name text-uppercase mt-3 mb-2">
                  Munjal's Residence
                </h6>
              </NavLink>
              <NavLink
                to="/work-three"
                className="d-flex flex-column  home-projects__small-box mb-3 mb-sm-5"
              >
                <img
                  src={dash4main}
                  alt="Dash Abode"
                  className="w-100 home-projects__small-box-image"
                />
                <h6 className="home-projects__name text-uppercase mt-3 mb-2">
                  Dash Abode
                </h6>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Work;
