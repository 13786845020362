import React, { useEffect } from "react";
import "./Footer.scss";
import logo from "../../assets/icons/logo.svg";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { NavLink, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const [pageSelected, setPageSelected] = React.useState("");
  const changeBackground = () => {
    if (document.location.href.includes("about")) {
      setPageSelected("aboutPage");
    } else {
      setPageSelected("");
    }
  };

  useEffect(() => {
    changeBackground();
  }, [location]);

  return (
    <div className="footer container-fluid">
      <div
        className={
          pageSelected === "aboutPage"
            ? "row justify-content-center footer-box footer-about border-top"
            : "row justify-content-center footer-box  border-top"
        }
      >
        <div className="col-md-10 text-center">
          <div className="row align-items-center my-md-4 my-3">
            <NavLink to="/" className="col-md-2 text-center">
              <img src={logo} alt="logo" width={75} />
              <h6 className="text-uppercase footer-logo">Idioma Spaces</h6>
            </NavLink>
            <div className="col-md-5 mt-3 mt-md-0">
              <div className="row align-items-center">
                <div className="col-4 d-flex flex-column align-items-center align-items-md-start">
                  <NavLink to="/">
                    <p className="mb-2">Home</p>
                  </NavLink>
                  <NavLink to="/about">
                    <p className="">About</p>
                  </NavLink>
                </div>
                <div className="col-4 d-flex flex-column align-items-center align-items-md-start">
                  <NavLink to="/contact">
                    <p className="mb-2">Contact</p>
                  </NavLink>
                  <NavLink to="/work">
                    <p className="">Projects</p>
                  </NavLink>
                </div>
                <div className="col-4 d-flex flex-column align-items-center align-items-md-start">
                  <NavLink to="/approach">
                    <p className="mb-2">Approach</p>
                  </NavLink>
                  <NavLink to="/services">
                    <p className="">Services</p>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-5 my-md-4 mt-5 mb-2">
              <div className="row ">
                <p>Copyright &copy; 2022 Idioma Spaces. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="designer col-12 py-2">
          <p className="d-flex align-items-center justify-content-center">
            Designed with&nbsp;
            <EmojiFoodBeverageIcon
              sx={{ color: "secondary", fontSize: 20 }}
              className="coffeeIcon"
            />
            &nbsp;and&nbsp;
            <FavoriteIcon sx={{ fontSize: 18 }} className="favIcon" />
            &nbsp;by&nbsp;
            <a
              href="https://hitensharma.netlify.app/"
              target="_blank"
              rel="noreferrer"
              className="hiten"
            >
              Hiten Sharma
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

// import React from "react";
// import "./Footer.scss";
// import twitter from "../../assets/icons/twitter.svg";
// import insta from "../../assets/icons/insta.svg";
// import linkedin from "../../assets/icons/linkedin.svg";
// import mail from "../../assets/icons/mail.svg";

// const Footer = () => {
//   return (
//     <div className="container-fluid footer pt-5">
//       <section className="row pt-0 pt-lg-5 pb-0 pb-md-5">
//         <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center mt-5 mt-lg-0 mb-4 mb-lg-0">
//           <div className="d-flex flex-column justify-content-center align-items-start ">
//             <div className="footer__heading d-flex justify-content-start align-items-start">
//               Ready&nbsp;<span>to</span>&nbsp;build
//             </div>
//             <div className="footer__heading d-flex justify-content-start align-items-start ">
//               <span>your</span> &nbsp;dream
//             </div>
//             <div className="footer__heading d-flex justify-content-start align-items-start ">
//               Project ?
//             </div>
//           </div>
//           <div className="d-flex text-capitalize footer__content text-center">
//             Interior Design Office in Bangalore
//           </div>
//           <div className="d-flex footer__box position-relative">
//             <img src={mail} alt="" className="footer__box-image" />
//             info@idiomaspaces.com
//           </div>
//         </div>
//         <div className="col-lg-6">
//           <section className="row ">
//             <div className="col-6 footer__social px-3 px-md-5 ">
//               <img src={twitter} alt="" className="w-100 footer__social-one" />
//               <img src={linkedin} alt="" className="w-100 footer__social-two" />
//             </div>
//             <div className="col-6 footer__social-right d-flex px-3 px-md-5 align-items-center">
//               <img src={insta} alt="" className="w-100 footer__social-three" />
//             </div>
//           </section>
//         </div>
//       </section>
//       <section className="row px-3 px-md-5 py-5 mt-5 align-items-center justify-content-center justify-content-md-between">
//         <div className="col-md-6 footer__company  text-center text-md-start">
//           Idioma Spaces
//         </div>
//         <div className="col-md-6 footer__rights text-center text-md-end mt-2 mt-md-0">
//           All Right Reserved.
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Footer;
