import React from "react";
import "./WorkThree.scss";
import Fancybox from "../../FancyApps";
import dash1 from "../../assets/images/workFinal/dash/dash1.jpg";
import dash2 from "../../assets/images/workFinal/dash/dash2.jpg";
import dash3 from "../../assets/images/workFinal/dash/dash3.jpg";
import dash4 from "../../assets/images/workFinal/dash/dash4main.jpg";
import dash5 from "../../assets/images/workFinal/dash/dash5.jpg";
import dash6 from "../../assets/images/workFinal/dash/dash6.jpg";
import dash7 from "../../assets/images/workFinal/dash/dash7.jpg";
import dash8 from "../../assets/images/workFinal/dash/dash8.jpg";
import dash9 from "../../assets/images/workFinal/dash/dash9.jpg";
import dash10 from "../../assets/images/workFinal/dash/dash10.jpg";
import dash11 from "../../assets/images/workFinal/dash/dash11.jpg";
import dash12 from "../../assets/images/workFinal/dash/dash12.jpg";
import dash13 from "../../assets/images/workFinal/dash/dash13.jpg";
import dash14 from "../../assets/images/workFinal/dash/dash14.jpg";
import dash15 from "../../assets/images/workFinal/dash/dash15.jpg";
import dash16 from "../../assets/images/workFinal/dash/dash16.jpg";
import dash17 from "../../assets/images/workFinal/dash/dash17.jpg";
import dash18 from "../../assets/images/workFinal/dash/dash18.jpg";
import { NavLink } from "react-router-dom";

const WorkThree = () => {
  return (
    <div className="container-fluid workDetail pb-5">
      <section className="row justify-content-center pt-5">
        <div className="col-12 col-md-10 text-center pt-5">
          <h2 className="text-center mb-5 mt-5 ">DASH ABODE</h2>
          {/* <h6 className="text-secondary pb-5">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores,
            quidem. Iusto, sunt ab! Vel, aperiam. Iusto recusandae assumenda
            magnam iste corporis optio cumque quod nobis vero labore, dolores
            quasi rerum! Lorem ipsum dolor sit amet consectetur, adipisicing
            elit. Maiores, quidem. Iusto, sunt ab! Vel, aperiam.
          </h6> */}
        </div>
        <div className="col-12 col-lg-10 px-3 px-md-5 px-lg-3">
          <Fancybox>
            <div className="row justify-content-between align-items-center">
              <a
                data-fancybox="gallery-work1"
                href={dash1}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash1}
                  alt="Work 1"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash2}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash2}
                  alt="Work 2"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash3}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash3}
                  alt="Work 3"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash4}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash4}
                  alt="Work 4"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash5}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash5}
                  alt="Work 5"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash6}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash6}
                  alt="Work 6"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash7}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash7}
                  alt="Work 7"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash8}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash8}
                  alt="Work 8"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash9}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash9}
                  alt="Work 9"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash10}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash10}
                  alt="Work 10"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash11}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash11}
                  alt="Work 11"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash12}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash12}
                  alt="Work 12"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash13}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash13}
                  alt="Work 13"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash14}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash14}
                  alt="Work 14"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash15}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash15}
                  alt="Work 15"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash16}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash16}
                  alt="Work 16"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash17}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash17}
                  alt="Work 17"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={dash18}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={dash18}
                  alt="Work 18"
                  className="workDetail-image w-100"
                />
              </a>
            </div>
          </Fancybox>
        </div>
        <div className="col-12 text-center mt-5 mb-4 my-md-5">
          <NavLink
            to="/work"
            className="btn-black w-auto rounded-pill py-3 px-5 shadow-lg"
          >
            View Other Projects
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default WorkThree;
