import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Footer from "./components/Footer/Footer";
import whatsapp from "./assets/icons/whatsapp.svg";
import {
  // Box,
  // Fab,
  // Fade,
  Link,
  // Slide,
  // Toolbar,
  // useScrollTrigger,
} from "@mui/material";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Approach from "./pages/Approach/Approach";
import Work from "./pages/Work/Work";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/Services/Services";
import WorkOne from "./pages/WorkOne/WorkOne";
import WorkTwo from "./pages/WorkTwo/WorkTwo";
import WorkThree from "./pages/WorkThree/WorkThree";

// function ScrollTop(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//     disableHysteresis: true,
//     threshold: 100,
//   });

//   const handleClick = (event) => {
//     const anchor = (event.target.ownerDocument || document).querySelector(
//       "#back-to-top-anchor"
//     );

//     if (anchor) {
//       anchor.scrollIntoView({
//         block: "center",
//       });
//     }
//   };

//   return (
//     <Fade in={trigger}>
//       <Box
//         onClick={handleClick}
//         role="presentation"
//         sx={{ position: "fixed", bottom: 16, right: 16 }}
//       >
//         {children}
//       </Box>
//     </Fade>
//   );
// }

const App = (props) => {
  let projectTheme = createTheme({
    palette: {
      primary: {
        main: "#f4f6f8",
      },
      secondary: {
        main: "#1d1e1c",
      },
      greyLight: {
        main: "#f4f6f8",
      },
      brownLight: {
        main: "#f7e8d5",
      },
    },
  });

  return (
    <ThemeProvider theme={projectTheme}>
      <Navbar />

      {/* <Toolbar /> */}
      <Link
        href="https://wa.me/919740099758?text=Hello%20There."
        target="_blank"
        className="w-auto px-0 whatsapp"
        rel="noopener noreferrer"
      >
        <img src={whatsapp} width={50} height={50} alt="" />
      </Link>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} exact />
        <Route path="/approach" element={<Approach />} exact />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/services" element={<Services />} exact />
        <Route path="/work" element={<Work />} exact />
        <Route path="/work-one" element={<WorkOne />} exact />
        <Route path="/work-two" element={<WorkTwo />} exact />
        <Route path="/work-three" element={<WorkThree />} exact />
      </Routes>
      <Footer />
      {/* <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}
    </ThemeProvider>
  );
};

export default App;
