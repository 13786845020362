import React from "react";
import "./About.scss";
// import about1 from "../../assets/images/about/about1.jpg";
// import about2 from "../../assets/images/about/about2.jpg";
// import about3 from "../../assets/images/about/about3.jpg";
import about4 from "../../assets/images/about/about4.jpg";
// import about6 from "../../assets/images/about/about6.jpg";
// import about7 from "../../assets/images/about/about7.jpg";
// import about8 from "../../assets/images/about/about8.jpg";
// import about9 from "../../assets/images/about/about9.jpg";
import surya from "../../assets/images/about/surya.jpg";
import bhashita4 from "../../assets/images/about/bhashita4.jpg";

const About = () => {
  return (
    <div className="container-fluid about pb-5">
      <div className="row justify-content-center pt-5">
        <div className="col-12 col-md-10 text-center mt-5">
          <h2 className="text-center mb-5 mt-5 pb-lg-5">ABOUT US</h2>
          <div className="row justify-content-between">
            <div className="col-xl-4 col-lg-6 col-12">
              <img src={surya} alt="about" className="w-100" />
            </div>
            <div className="col-xl-6 col-lg-6 col-12 text-center text-md-start mt-lg-0 mt-5 pb-5">
              <p className="about-small">IDIOMA SPACES</p>
              <h5 className="about-large mt-4">
                Founded in 2021 by Surya & Bhashita, Idioma Spaces is a
                Bengaluru architectural and interior design firm comprising
                associate architects, landscape architects, and project
                managers.
              </h5>
              <h6 className="about-medium mt-5">
                Our goal is to stay small so that we can be part of every
                project while providing our clients with a very practical,
                highly innovative, and customized experience. Everyone working
                in our Bengaluru workspace is professionally qualified and a
                member of their relevant associations.
                <br />
                <br />
                We have an extensive library of finishes and materials which are
                being constantly updated, the technical knowledge and the
                necessary technology. This way we are able to transform a vision
                into reality.
              </h6>
            </div>
          </div>
          {/* <div className="row justify-content-end">
            <div className="col-12 col-md-9 my-5 py-5">
              <img src={about2} alt="about" className="w-100" />
            </div>
          </div>
          <div className="row justify-content-between flex-column-reverse flex-lg-row">
            <div className="col-xl-6 col-lg-6 col-12 text-center text-md-start mb-5 pb-lg-5 pb-5">
              <h5 className="about-large">
                "London interior design practice - Daniel Hopwood work in
                progress We are absolutely thrilled with the end result; there
                isn't a person who has come into the apartment, be they friends
                or delivery drivers, who haven't been bowled over!"
              </h5>
              <p className="about-small mt-5">- HAPPY CLIENT</p>
            </div>
            <div className="col-xl-4 col-lg-6 col-12 mb-5 pb-lg-5 pb-0">
              <img src={about3} alt="about" className="w-100" />
            </div>
          </div> */}
          <div className="row justify-content-between mt-5 flex-column-reverse flex-md-row">
            <div className="col-xl-6 col-lg-6 col-12 text-center text-md-start mt-lg-0 mt-5">
              <p className="about-small">THE CLIENTS</p>
              <h6 className="about-medium mt-4">
                Over the years, we have worked ranging from brand new apartments
                that only need to be furnished to full renovations and re-fits
                of period houses and mansion blocks.
                <br />
                <br />A significant number of our clients own a unique sense of
                style and are reluctant to adopt a branded appearance. As a
                result, even though they might not have a lot of time or may
                reside elsewhere, they enjoy being involved in the creative
                process. We can use our knowledge and resources to make the
                entire process efficient and satisfying.
              </h6>
            </div>
            <div className="col-xl-4 col-lg-6 col-12">
              <img src={about4} alt="about" className="w-100" />
            </div>
          </div>
          {/* <div className="row justify-content-end">
            <div className="col-12 col-md-9 my-5 py-5">
              <img src={about6} alt="about" className="w-100" />
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-xl-6 col-lg-6 col-12 mb-5 pb-lg-5 pb-0">
              <img src={about7} alt="about" className="w-100" />
            </div>
            <div className="col-xl-5 col-lg-6 col-12 text-center text-md-start mb-5 pb-lg-5 pb-5">
              <h5 className="about-large">
                "We are looking forward to our project standing shoulder to
                shoulder with the others on your website, although we do think
                our flat is the best! "
              </h5>
              <p className="about-small mt-5">- HAPPY CLIENT</p>
            </div>
          </div> */}
          <div className="row justify-content-between flex-lg-row">
            <div className="col-xl-5 col-lg-6 col-12 mb-5 pb-lg-5 pb-0 mt-5 pt-5">
              <img src={bhashita4} alt="about" className="w-100" />
            </div>
            <div className="col-xl-6 col-lg-6 col-12 text-center text-md-start mb-5 pb-lg-5 pb-5 mt-0 mt-md-5 pt-0 pt-md-5">
              <p className="about-small">REALISATION</p>
              <h6 className="about-medium mt-5">
                Idioma Spaces is one of few companies that design unique plans
                and have the expertise to develop original plans that
                incorporate everything from furnishings to structural
                construction.
                <br />
                <br />
                We have a large network of talented, dependable individuals that
                we have worked with for years and can rely on when needed. They
                include professionals and craftspeople as well as structural
                engineers and makers of soft furnishings.
                <br />
                <br />
                To ensure that our projects are finished on schedule and under
                budget, we thoroughly prepare them before they even set foot on
                the job site. We also keep a close eye on every project to
                ensure the quality meets our standard of perfection.
              </h6>
            </div>
          </div>
          {/* <div className="row justify-content-end">
            <div className="col-12 col-md-9 my-5 pb-5">
              <img src={about9} alt="about" className="w-100" />
            </div>
          </div> */}
          <div className="row">
            <p className="about-small col-12 text-start ps-5">KEY ATTRIBUTES</p>
            <ul className="mt-3 col-12 col-md-auto ps-5">
              <li>
                <h6 className="about-medium my-2 text-start text-start">
                  3D Visualisations
                </h6>
              </li>
              <li>
                <h6 className="about-medium my-2 text-start">
                  Technical Drafting
                </h6>
              </li>
              <li>
                <h6 className="about-medium my-2 text-start">
                  Project Management
                </h6>
              </li>
              <li>
                <h6 className="about-medium my-2 text-start">
                  Fixed Furniture & Styling
                </h6>
              </li>
            </ul>
            <ul className="mt-3 col-12 col-md-auto ps-5">
              <li>
                <h6 className="about-medium my-2 text-start">
                  Creative Collaboration
                </h6>
              </li>
              <li>
                <h6 className="about-medium my-2 text-start">
                  In House Soft Furnishings
                </h6>
              </li>
              <li>
                <h6 className="about-medium my-2 text-start">
                  Architectural Services
                </h6>
              </li>
              <li>
                <h6 className="about-medium my-2 text-start">
                  Budget Administration
                </h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
