import React from "react";
import "./WorkTwo.scss";
import Fancybox from "../../FancyApps";
import munjal1 from "../../assets/images/workFinal/munjal/munjal1.jpg";
import munjal2 from "../../assets/images/workFinal/munjal/munjal2.jpg";
import munjal3 from "../../assets/images/workFinal/munjal/munjal3.jpg";
import munjal4 from "../../assets/images/workFinal/munjal/munjal4main.jpg";
import munjal5 from "../../assets/images/workFinal/munjal/munjal5.jpg";
import munjal6 from "../../assets/images/workFinal/munjal/munjal6.jpg";
import munjal7 from "../../assets/images/workFinal/munjal/munjal7.jpg";
import munjal8 from "../../assets/images/workFinal/munjal/munjal8.jpg";
import munjal9 from "../../assets/images/workFinal/munjal/munjal9.jpg";
import munjal10 from "../../assets/images/workFinal/munjal/munjal10.jpg";
import munjal11 from "../../assets/images/workFinal/munjal/munjal11.jpg";
import munjal12 from "../../assets/images/workFinal/munjal/munjal12.jpg";
import munjal13 from "../../assets/images/workFinal/munjal/munjal13.jpg";
import munjal14 from "../../assets/images/workFinal/munjal/munjal14.jpg";
import munjal15 from "../../assets/images/workFinal/munjal/munjal15.jpg";
import munjal16 from "../../assets/images/workFinal/munjal/munjal16.jpg";
import munjal17 from "../../assets/images/workFinal/munjal/munjal17.jpg";
import munjal18 from "../../assets/images/workFinal/munjal/munjal18.jpg";
import munjal19 from "../../assets/images/workFinal/munjal/munjal19.jpg";
import munjal20 from "../../assets/images/workFinal/munjal/munjal20.jpg";
import munjal21 from "../../assets/images/workFinal/munjal/munjal21.jpg";
import { NavLink } from "react-router-dom";

const WorkTwo = () => {
  return (
    <div className="container-fluid workDetail pb-5">
      <section className="row justify-content-center pt-5">
        <div className="col-12 col-md-10 text-center pt-5">
          <h2 className="text-center mb-5 mt-5 ">MUNJAL'S RESIDENCE</h2>
          {/* <h6 className="text-secondary pb-5">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores,
            quidem. Iusto, sunt ab! Vel, aperiam. Iusto recusandae assumenda
            magnam iste corporis optio cumque quod nobis vero labore, dolores
            quasi rerum! Lorem ipsum dolor sit amet consectetur, adipisicing
            elit. Maiores, quidem. Iusto, sunt ab! Vel, aperiam.
          </h6> */}
        </div>
        <div className="col-12 col-lg-10 px-3 px-md-5 px-lg-3">
          <Fancybox>
            <div className="row justify-content-between align-items-center">
              <a
                data-fancybox="gallery-work1"
                href={munjal1}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal1}
                  alt="Work 1"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal2}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal2}
                  alt="Work 2"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal3}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal3}
                  alt="Work 3"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal4}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal4}
                  alt="Work 4"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal5}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal5}
                  alt="Work 5"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal6}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal6}
                  alt="Work 6"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal7}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal7}
                  alt="Work 7"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal8}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal8}
                  alt="Work 8"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal9}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal9}
                  alt="Work 9"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal10}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal10}
                  alt="Work 10"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal11}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal11}
                  alt="Work 11"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal12}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal12}
                  alt="Work 12"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal13}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal13}
                  alt="Work 13"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal14}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal14}
                  alt="Work 14"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal15}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal15}
                  alt="Work 15"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal16}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal16}
                  alt="Work 16"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal17}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal17}
                  alt="Work 17"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal18}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal18}
                  alt="Work 18"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal19}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal19}
                  alt="Work 19"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal20}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal20}
                  alt="Work 20"
                  className="workDetail-image w-100"
                />
              </a>
              <a
                data-fancybox="gallery-work1"
                href={munjal21}
                className="col-md-4 col-6 mb-3 mb-md-5 px-3 px-md-4"
              >
                <img
                  src={munjal21}
                  alt="Work 21"
                  className="workDetail-image w-100"
                />
              </a>
            </div>
          </Fancybox>
        </div>
        <div className="col-12 text-center mt-5 mb-4 my-md-5">
          <NavLink
            to="/work"
            className="btn-black w-auto rounded-pill py-3 px-5 shadow-lg"
          >
            View Other Projects
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default WorkTwo;
