import { TextareaAutosize, TextField } from "@mui/material";
import React from "react";
import "./Contact.scss";

const Contact = () => {
  return (
    <div className="container-fluid contact pb-5">
      <section className="row justify-content-center pt-5">
        <div className="col-12 col-md-10 text-center pt-5">
          <h2 className="text-center mb-5 mt-5 pb-lg-5">GET IN TOUCH</h2>
        </div>
        <div className="col-12 col-lg-10 px-3 px-md-5 px-lg-3">
          <div className="row justify-content-between">
            <div className="col-md-6 text-md-start">
              <h5 className="mb-3">CONTACT US</h5>
              <h6 className="my-2">Idioma Spaces</h6>
              <h6 className="my-2">email: info@idiomaspaces.com</h6>
              <h6 className="my-2">mobile: +91-7976290701</h6>
              <h6 className="my-5 pe-0 pe-md-5">
                If you would like to discuss any current or future interior
                design or styling project – big or small, please get in touch.
                We would love to hear from you.
              </h6>
              <h4 className="text-secondary fw-bolder d-none d-md-block">
                “THINKING ABOUT
                <br />
                DESIGN IS HARD,
                <br />
                BUT NOT THINKING
                <br />
                ABOUT IT CAN BE
                <br />
                DISASTROUS.”
              </h4>
              <h5 className="text-secondary fw-bolder d-block d-md-none">
                “THINKING ABOUT DESIGN IS HARD, BUT NOT THINKING ABOUT IT CAN BE
                DISASTROUS.”
              </h5>
            </div>
            <form
              className="col-md-6"
              action="https://formspree.io/f/xpzgbybv"
              method="POST"
            >
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    className="w-100"
                    color="secondary"
                    required
                    name="First Name"
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    className="w-100"
                    color="secondary"
                    name="Last Name"
                  />
                </div>
                <div className="col-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Email Address"
                    variant="outlined"
                    className="w-100"
                    color="secondary"
                    required
                    name="Email"
                  />
                </div>
                <div className="col-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Project Location"
                    variant="outlined"
                    className="w-100"
                    color="secondary"
                    name="Project Location"
                  />
                </div>
                <div className="col-12 mt-4 text-secondary">
                  Describe your Project
                </div>
                <div className="col-12 mt-2">
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={5}
                    maxRows={5}
                    placeholder=""
                    className="w-100"
                    color="secondary textarea"
                    name="Message"
                    required
                  />
                </div>
                <div className="text-center text-md-start col-12">
                  <button
                    className="btn-black w-auto rounded-pill py-3 px-5 mt-5"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-12 pt-5 px-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.4413030731675!2d77.64301907460248!3d12.879320516895696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1554a47408cf%3A0x392c494e41aae753!2sIdioma%20Spaces!5e0!3m2!1sen!2sin!4v1708890953956!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Idioma Spaces Location"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Contact;
