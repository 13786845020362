import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Navbar.scss";
import logo from "../../assets/icons/logo.svg";
import navbar from "../../assets/images/navbar/navbar.jpg";

const Navbar = (props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [viewScrolled, setViewScrolled] = React.useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      if (document.location.href.includes("about")) {
        setViewScrolled("about");
      } else {
        setViewScrolled("home");
      }
    } else {
      setViewScrolled("");
    }
  };

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 10) {
        if (document.location.href.includes("about")) {
          setViewScrolled("about");
        } else {
          setViewScrolled("home");
        }
      } else {
        setViewScrolled("");
      }
    };
    changeBackground();
  }, [location]);

  window.addEventListener("scroll", changeBackground);

  return (
    <nav
      className={
        (viewScrolled === "about" &&
          "navbar navbar-expand-lg fixed-top navbar-about-scrolled shadow-sm py-0") ||
        (viewScrolled === "home" &&
          "navbar navbar-expand-lg fixed-top navbar-home-scrolled shadow-sm  py-0") ||
        (viewScrolled === "" && "navbar navbar-expand-lg fixed-top  py-0")
      }
    >
      <div className="container">
        <div className="col-4 col-md-3 navbar-box">
          <div
            className={!mobileOpen ? "menu-toggler" : "menu-toggler open "}
            onClick={handleDrawerToggle}
          >
            <div className="bar half start"></div>
            <div className="bar"></div>
            <div className="bar half end"></div>
          </div>
        </div>
        <nav className={!mobileOpen ? "nav " : "nav open"}>
          <div className="col-12 d-flex align-items-start align-items-md-center">
            <div className="d-flex justify-content-between w-100">
              <ul className="nav__list w-100  mt-5 mt-md-0 pt-5 pt-md-0 px-0">
                <li className="col-12  pe-0 text-center text-md-start">
                  <NavLink
                    to="/"
                    className="nav__list-item"
                    onClick={handleDrawerToggle}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="col-12 px-0 text-center text-md-start">
                  <NavLink
                    to="/about"
                    className="nav__list-item"
                    onClick={handleDrawerToggle}
                  >
                    About
                  </NavLink>
                </li>
                <li className="col-12 px-0 text-center text-md-start">
                  <NavLink
                    to="/contact"
                    className="nav__list-item"
                    onClick={handleDrawerToggle}
                  >
                    Contact
                  </NavLink>
                </li>
                <li className="col-12 px-0 text-center text-md-start">
                  <NavLink
                    to="/work"
                    className="nav__list-item"
                    onClick={handleDrawerToggle}
                  >
                    Projects
                  </NavLink>
                </li>
                <li className="col-12 px-0 text-center text-md-start">
                  <NavLink
                    to="/approach"
                    className="nav__list-item"
                    onClick={handleDrawerToggle}
                  >
                    Approach
                  </NavLink>
                </li>
                <li className="col-12 px-0 text-center text-md-start">
                  <NavLink
                    to="/services"
                    className="nav__list-item"
                    onClick={handleDrawerToggle}
                  >
                    Services
                  </NavLink>
                </li>
              </ul>
              <div className="col-md-4 col-lg-4 d-none d-md-flex justify-content-center align-items-center nav-image">
                <img src={navbar} alt="navbar" />
              </div>
              <div className="navbar-backbox-two d-none d-lg-block"></div>
            </div>
          </div>
        </nav>
        <NavLink
          to="/"
          onClick={() => setMobileOpen(false)}
          className="text-decoration-none d-flex justify-content-center align-items-center d-flex  col-4 col-md-6 text-center"
        >
          <img
            src={logo}
            height={80}
            width={80}
            alt="logo"
            className="mr-3  navbar-logo__image d-none d-md-block"
          />
          <img
            src={logo}
            height={60}
            width={60}
            alt="logo"
            className="mr-3  navbar-logo__image d-block d-md-none"
          />
          &nbsp;
          <h5 className="text-uppercase navbar-logo__mobile d-none d-md-block">
            Idioma Spaces
          </h5>
        </NavLink>
        <div className="d-flex col-4 col-md-3 justify-content-end navbar-button">
          <NavLink
            to="/contact"
            className="btn-black rounded-pill py-2 px-3 px-lg-5 d-none d-md-block"
          >
            CONTACT US
          </NavLink>
          <NavLink
            to="/contact"
            className="btn-black rounded-pill py-2 px-2 px-sm-3 d-block d-md-none "
          >
            Connect
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
