import React, { useEffect } from "react";
import "./Services.scss";
import service1 from "../../assets/images/services/service1.jpg";
import service2 from "../../assets/images/services/service2.jpg";
import service3 from "../../assets/images/services/service3.jpg";
import service4 from "../../assets/images/services/service4.jpg";
import AppContact from "../../components/AppContact/AppContact";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <div className="container-fluid services pb-5 " data-aos="fade-up">
      <div className="row justify-content-center pt-5" data-aos="fade-up">
        <div className="col-12 col-md-10 text-center mt-5">
          <h2 className="text-center mt-5 pb-3">OUR SERVICES</h2>
        </div>
      </div>
      <div className="row justify-content-center " data-aos="fade-up">
        <div className="col-12 col-md-10 text-center" data-aos="fade-up">
          <h6 className="text-center mb-5 pb-lg-5">
            Over the past years, Idioma Spaces has made its name within the
            industry for interiors that are intelligent, elegant, dynamic and
            infused with passion and creativity. Our interior design studio is
            talented, friendly, approachable and absolutely committed to
            achieving the best design solution possible for any given project,
            whether private residence, show suite, hotel, spa or restaurant
            environment.
          </h6>
        </div>
      </div>
      <div className="row mb-5" data-aos="fade-up">
        <div className="col-lg-5 col-md-6 px-4 ps-lg-0" data-aos="zoom-in">
          <img src={service1} alt="service1" className="w-100" />
        </div>
        <div
          className="col-md-6 col-lg-7   px-4 px-md-3 px-lg-5 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
          data-aos="fade-up"
        >
          <h4 className="mb-4 ">Interior Design</h4>
          <h6>
            Our team of interior designers interpret each client’s passions and
            style to provide innovative and exquisite interiors, curating
            furniture, textiles, art and antiques. Interior spaces are often
            completely re-imagined beyond the decorative, to eliminate
            boundaries between the built environment and a better way of
            life. Each project is as personalised as a piece of art or couture.
            It is precisely this expression of design and dedication to every
            detail that has seen clients become faithful followers of Idioma
            Spaces.
          </h6>
        </div>
      </div>
      <div
        className="row mb-5  flex-column-reverse flex-md-row"
        data-aos="fade-up"
      >
        <div
          className="col-md-6 col-lg-7   px-4 px-md-3 px-lg-5 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
          data-aos="fade-up"
        >
          <h4 className="mb-4 ">Interior Architecture</h4>
          <h6>
            Delivering a diverse scale of projects including new build
            developments, restorations and refurbishments, our experienced team
            of interior architects, designers & visualisers offer a service
            tailored to each individual project from concept to completion. As
            interior design professionals we respond to each project with  an
            individual approach taking into consideration the design integrity
            of each home.When crafting our interiors we as a studio aim to
            create luxurious spaces that suit the ebb-and-flow of everyday
            life. We concentrate on giving our client’s a home that is truly
            personal to them and their family, whilst also bearing in mind the
            timeless elegance that defines Idioma Spaces.
          </h6>
        </div>
        <div className="col-lg-5 col-md-6 px-4 pe-lg-0" data-aos="zoom-in">
          <img src={service2} alt="service1" className="w-100" />
        </div>
      </div>
      <div className="row mb-5" data-aos="fade-up">
        <div className="col-lg-5 col-md-6 px-4 ps-lg-0" data-aos="zoom-in">
          <img src={service3} alt="service1" className="w-100" />
        </div>
        <div
          className="col-md-6 col-lg-7   px-4 px-md-3 px-lg-5 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
          data-aos="fade-up"
        >
          <h4 className="mb-4 ">FF&E Design</h4>
          <h6>
            Working in tandem with our interior architects and interior
            designers, our furniture, fittings and equipment (FF&E) design team
            create schemes of soft finishes and furniture for each project with
            the client’s lifestyle needs playing a key role in the design and
            selection process of all pieces. By taking this holistic approach
            our FF&E team stay true to our design philosophy of ‘True Design for
            Living’ to ensure that all design decisions are made in keeping with
            not only the look and feel of the overall interior design scheme
            but, also the client’s individual lifestyle needs.
          </h6>
        </div>
      </div>
      <div
        className="row mb-5 flex-column-reverse flex-md-row"
        data-aos="fade-up"
      >
        <div
          className="col-md-6 col-lg-7   px-4 px-md-3 px-lg-5 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
          data-aos="fade-up"
        >
          <h4 className="mb-4 ">Procurement</h4>
          <h6>
            Our interior design and procurement team invest a large amount of
            time in developing strong relationships with a wide range of
            incredibly talented craftspeople and designer-makers so as to offer
            our clients both the best of off-the-shelf and bespoke
            products. Every detail within a project is considered by our
            procurement team and finalised during a full installation service
            where the interior design and procurement team are present on site
            to provide our clients with a luxury turn key service.
          </h6>
        </div>
        <div className="col-lg-5 col-md-6 px-4 ps-lg-0" data-aos="zoom-in">
          <img src={service4} alt="service1" className="w-100" />
        </div>
      </div>
      <div className="row justify-content-center" data-aos="fade-up">
        <div className="col-12 col-md-10 text-center mt-0 mt-md-5">
          <h4 className="text-center mt-5 pb-3">OUR EXPERTISE</h4>
        </div>
      </div>
      <div className="row justify-content-center " data-aos="fade-up">
        <div className="col-12 col-md-10 text-center">
          <h6 className="text-center mb-5">
            With an astute appreciation of today’s private wealth market, we
            have designed and executed number of projects. Our designers and
            dedicated procurement team provide the highest level of bespoke,
            turn-key solutions. Hand-in-hand, this has led to unprecedented
            success for agents, developers, and longstanding repeat private
            clients. Whether creating bespoke projects for private clients,
            aspirational show homes for commercial developments or boutique
            hotels, Idioma Spaces aims to deliver interiors that don’t just look
            spectacular, but which function brilliantly on a day-to-day level as
            well.
          </h6>
        </div>
      </div>
      <div className="row justify-content-center pb-5 mb-5" data-aos="fade-up">
        <div className="col-12 col-md-10 text-center">
          <button className="btn-black w-auto rounded-pill py-3 px-5 shadow-lg">
            View Our Projects
          </button>
        </div>
      </div>

      <AppContact data-aos="fade-up" />
    </div>
  );
};

export default Services;
