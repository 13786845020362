import React, { useEffect } from "react";
import "./AppContact.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

const AppContact = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <section
      className="row app-contact justify-content-center align-items-center flex-column"
      data-aos="fade-up"
    >
      <h2 className="text-center mb-4" data-aos="fade-up">
        LET'S CONNECT
      </h2>
      <h4 className="mt-5 mb-4 text-center" data-aos="fade-up">
        Want to elevate how you live in your home?
      </h4>
      <h6 className="mb-5 mt-3 text-center" data-aos="fade-up">
        Book a complimentary consultation with Idioma Spaces and learn more.
      </h6>
      <NavLink
        to="/contact"
        className="btn-black w-auto rounded-pill py-3 px-3 px-md-5 shadow-lg"
      >
        Contact Us
      </NavLink>
    </section>
  );
};

export default AppContact;
