import React, { useEffect } from "react";
import "./Approach.scss";
import approachHero from "../../assets/images/approach/approach.jpg";
import approachOne from "../../assets/images/approach/approach1.svg";
import approachTwo from "../../assets/images/approach/approach2.svg";
import approachThree from "../../assets/images/approach/approach3.svg";
import approachFour from "../../assets/images/approach/approach4.svg";
import AppContact from "../../components/AppContact/AppContact";
import AOS from "aos";
import "aos/dist/aos.css";

const Approach = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <div className="container-fluid approach">
      <section className="row approach-hero">
        <div className="col-12 px-0">
          <img src={approachHero} alt="approach-hero" className="w-100" />
        </div>
      </section>
      <div className="row justify-content-center pt-5">
        <div className="col-12 col-md-10 text-center">
          <h2 className="text-center">OUR APPROACH</h2>
        </div>
      </div>
      <section className="row approach-intro">
        <h6 className="text-center text-secondary">
          At Idioma Space, We don’t impose our style upon anyone, but co-design
          with you through a series of meetings. This information will guide and
          inform every decision we make through the project.
        </h6>
      </section>
      <section className="row approach-steps">
        <div className="col-12">
          <div className="row approach-steps__box">
            <div className="col-md-4 mb-5">
              <img src={approachOne} alt="step 1" className="w-100" />
            </div>
            <div className="col-md-8 ps-3 ps-md-5 mb-5 d-flex flex-column justify-content-center">
              <div className="d-flex position-relative">
                <h1 className="approach-number">01</h1>
                <h5 className="approach-heading">MEET AND AGREE</h5>
              </div>
              <h6 className="text-secondary mt-4 approach-content">
                Our kick-off meeting. Work through inspiration, design styles,
                loose plans and ideas. Set a general design direction, budget
                and We will measure or talk through your architect’s/draftsman’s
                plans.
              </h6>
            </div>
          </div>
          <div className="row approach-steps__box flex-column-reverse flex-md-row">
            <div className="col-md-8 pe-3 pe-md-5 mb-5 d-flex flex-column justify-content-center">
              <div className="d-flex position-relative">
                <h1 className="approach-number">02</h1>
                <h5 className="approach-heading">CONCEPT DESIGN</h5>
              </div>
              <h6 className="text-secondary mt-4 approach-content">
                We will present mood-boards, a loose concept supported by floor
                plans, materials samples and imagery. With your feedback, We can
                go away and finish designing.
              </h6>
            </div>
            <div className="col-md-4 mb-5">
              <img src={approachTwo} alt="step 2" className="w-100" />
            </div>
          </div>
          <div className="row approach-steps__box">
            <div className="col-md-4 mb-5">
              <img src={approachThree} alt="step 3" className="w-100" />
            </div>
            <div className="col-md-8 ps-3 ps-md-5 mb-5 d-flex flex-column justify-content-center">
              <div className="d-flex position-relative">
                <h1 className="approach-number">03</h1>
                <h5 className="approach-heading">
                  Design Development and Documentation
                </h5>
              </div>
              <h6 className="text-secondary mt-4 approach-content">
                We finalise plans and design details. This includes completion
                of finishes schedules, floor plans, elevations, and sometimes 3D
                renders. We provide a full pack of samples, and we review quotes
                from builders, joiners and sourced items.
              </h6>
            </div>
          </div>
          <div className="row approach-steps__box flex-column-reverse flex-md-row">
            <div className="col-md-8 pe-3 pe-md-5 mb-5 d-flex flex-column justify-content-center">
              <div className="d-flex position-relative">
                <h1 className="approach-number">04</h1>
                <h5 className="approach-heading">
                  Construction/Design Management
                </h5>
              </div>
              <h6 className="text-secondary mt-4 approach-content">
                You can choose to engage ud throughout the construction phase of
                your project. We work with the suppliers to so everything is
                ordered correctly and delivered on time. We will ensure your
                design is realised and even manage your defect list at the end
                of the project before final handover.
              </h6>
            </div>
            <div className="col-md-4 mb-5">
              <img src={approachFour} alt="step 4" className="w-100" />
            </div>
          </div>
        </div>
      </section>

      <AppContact />
    </div>
  );
};

export default Approach;
